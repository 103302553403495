<template>
  <div class="">
    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="items.length">
          <section class="input-filters">
            <InputSearchComponent @onChangeSearch="onChangeSearch" />
            <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
          </section>

          <paginate
            class="wrapper-pagination"
            name="resultQueryContacts"
            :list="resultQueryContacts"
            :per="perPaginate"
            :key="searchQuery"
          >
            <div class="card-content-items">
              <b-row>
                <b-col
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="3"
                  v-for="(item, index) in paginated('resultQueryContacts')"
                  :key="index"
                >
                  <div
                    class="card card-shadow mb-4"
                    @click="onOpenDialogEditComponent(item)"
                  >
                    <div class="card-body card-shadow-body">
                      <div class="business-content-item p-2">
                        <div class="business-image-item">
                          <ImagePreviewCircleComponent :urlImage="item.image" />
                        </div>
                        <div class="business-description-item">
                          <h3 class="h3 font-bold mb-0 str-limit-space">
                            {{ item.name }}
                          </h3>
                          <small class="small">Correo: {{ item.email }}</small>
                          <small class="small" v-if="item.phone"
                            >Telefono: {{ item.phone }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </paginate>
          <paginate-links
            v-show="resultQueryContacts.length > perPaginate"
            for="resultQueryContacts"
            :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
            :simple="{
              next: 'Siguiente »',
              prev: '« Regresar'
            }"
          ></paginate-links>
        </template>
        <EmptyPage
          :media="emptyMedia1"
          text="Aún no has registrado empresas, empieza a crear tu lista de contactos para ofrecer tus productos o servicios."
          v-else
        />
      </template>
      <SkeletonLoadingCard v-else />
    </b-container>
    <!-- dialog to add new contact -->
    <el-dialog
      :visible.sync="dialogEditContact"
      :fullscreen="false"
      :before-close="handleCloseContactEdit"
    >
      <EditContactComponent
        :contact="contact"
        :showBackIcon="false"
        v-if="dialogEditContact"
        @onSuccess="onSuccessContactEdit"
      />
    </el-dialog>
  </div>
</template>

<script>
//import TopBarTitleComponent from "../../components/TopBarTitle";
import InputSearchComponent from "../../components/InputSearch";
import ImagePreviewCircleComponent from "../../components/ImagePreviewCircle";
import SelectOrderByComponent from "../../components/SelectOrderBy";
import SkeletonLoadingCard from "@/components/Animations/Loading/SkeletonCard";
//import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import EmptyPage from "../../components/EmptyPage";
import EditContactComponent from "./EditContact";

import businessService from "../services/businessService";
import { emptyMedia1 } from "@/services/resources";

export default {
  name: "BusinessListContactComponent",
  data: () => ({
    dialogEditContact: false,
    contact: {},
    emptyMedia1,
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    paginate: ["resultQueryContacts"],
    perPaginate: 12,
    items: []
  }),
  computed: {
    resultQueryContacts() {
      let clients = this.items;
      return clients
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            case "z-a":
              return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    onOpenDialogEditComponent(contact) {
      this.dialogEditContact = true;
      this.contact = contact;
    },
    onSuccessContactEdit() {
      this.dialogEditContact = false;
      this.contact = {};
      this.handleGetContacts();
    },
    handleCloseContactEdit() {
      this.dialogEditContact = false;
    },
    async handleGetContacts() {
      try {
        let response = await businessService.getContacts();
        if (response.data.contacts) {
          this.items = response.data.contacts;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    ImagePreviewCircleComponent,
    SelectOrderByComponent,
    SkeletonLoadingCard,
    EmptyPage,
    EditContactComponent
  },
  mounted() {
    this.handleGetContacts();
  }
};
</script>

<style lang="scss" scoped>
.card-content-items {
  .business-content-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 120px;

    .business-image-item {
      margin-right: 1rem;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
}
</style>
