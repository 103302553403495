<template>
  <el-form :model="form" :rules="rules" ref="form">
    <b-container>
      <div class="card card-shadow my-3">
        <div class="card-body card-shadow-body">
          <b-row>
            <b-col sm="12" md="12" lg="4" xl="4">
              <FileUploadComponent
                icon="el-icon-user"
                title="Subir Foto"
                className="image-profile"
                :showFileList="false"
                @fileBase64="base64 => onFileBase64(base64, indexContact)"
              />
            </b-col>
          </b-row>
          <h4 class="h4 font-bold line-bottom my-4 text-color-primary">
            Información de Contacto
          </h4>
          <b-row>
            <b-col sm="6">
              <el-form-item
                prop="name"
                :rules.sync="getSyncRules && getSyncRules.rules.required()"
              >
                <slot name="label">
                  <label class="font-semi-bold m-0">Nombre de contacto</label>
                </slot>
                <el-input type="text" v-model="form.name"></el-input>
              </el-form-item>
            </b-col>
            <b-col sm="6">
              <el-form-item
                prop="email"
                :rules.sync="getSyncRules && getSyncRules.rules.required()"
              >
                <slot name="label">
                  <label class="font-semi-bold m-0">Correo</label>
                </slot>
                <el-input type="text" v-model="form.email"></el-input>
              </el-form-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <el-form-item>
                <slot name="label">
                  <label class="font-semi-bold m-0">Teléfono</label>
                </slot>
                <el-input type="text" v-model="form.phone"></el-input>
              </el-form-item>
            </b-col>
            <b-col sm="6">
              <el-form-item>
                <slot name="label">
                  <label class="font-semi-bold m-0">Cargo</label>
                </slot>
                <el-input type="text" v-model="form.position"></el-input>
              </el-form-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ButtonPrimaryComponent
                title="Editar"
                @onSaveClick="handleSaveClick"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </el-form>
</template>

<script>
import FileUploadComponent from "../../components/FileUpload.vue";
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import businessService from "../services/businessService";
import Validate from "@/assets/validate";

export default {
  name: "EditContact",
  props: {
    contact: Object
  },
  data() {
    return {
      form: {
        id: "",
        name: "",
        position: "",
        phone: "",
        email: "",
        image: ""
      },
      rules: {
        name: [Validate.rules.required()],
        email: [Validate.rules.required(), Validate.rules.email()]
      }
    };
  },
  computed: {
    getSyncRules() {
      return Validate;
    }
  },
  methods: {
    onFileBase64(base64) {
      this.form.image = base64;
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await businessService
              .updateContactId(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$refs.form.resetFields();
              this.$root.$emit("cleanFileUpload");
              this.onFileBase64("");

              this.$notifications.success({
                message
              });

              this.$emit("onSuccess");
            }
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  created() {
    this.form = this.contact;
  },
  components: {
    FileUploadComponent,
    ButtonPrimaryComponent
  }
};
</script>

<style></style>
