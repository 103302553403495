export const messageRequired = "Este campo es requerido";
export const messageNumeric = "Este campo debe ser digitos";
export const messageEmailValid = "Debe ser un email valido";
export const messagePasswordDiferent = "Contraseñas diferentes";

export default {
  rules: {
    required: (trigger = "blur") => ({
      required: true,
      message: messageRequired,
      trigger
    }),

    numeric: (type = "number", trigger = "blur") => ({
      type,
      message: messageNumeric,
      trigger
    }),

    email: (trigger = ["blur", "change"]) => ({
      type: "email",
      message: messageEmailValid,
      trigger
    }),

    compare: {
      password: function(formRef, rule, value, callback) {
        if (value === "") {
          callback(new Error(messageRequired));
        } else {
          if (this.passwordChange.passwordConfirm !== "") {
            this.$refs[formRef].validateField("passwordConfirm");
          }
          callback();
        }
      },
      confirmPassword: function(rule, value, callback) {
        if (value === "") {
          callback(new Error(messageRequired));
        } else if (value !== this.passwordChange.password) {
          callback(new Error(messagePasswordDiferent));
        } else {
          callback();
        }
      }
    }
  }
};
