<template>
  <section>
    <TopBarTitleComponent title="Directorio" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent
            title="Nueva Empresa"
            @onSaveClick="goRouter('business.add')"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="Empresas" name="business">
          <b-row>
            <b-col>
              <ListBusinessComponent />
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane label="Contactos" name="contacts">
          <b-row>
            <b-col>
              <ListBusinessContactsComponent />
            </b-col>
          </b-row>
        </el-tab-pane>
      </el-tabs>
    </b-container>
  </section>
</template>

<script>
import TopBarTitleComponent from "../components/TopBarTitle";
import ListBusinessComponent from "./components/List";
import ListBusinessContactsComponent from "./components/ListContacts";
import ButtonPrimaryComponent from "../components/ButtonPrimary";

export default {
  name: "MainBusiness",
  components: {
    TopBarTitleComponent,
    ListBusinessComponent,
    ListBusinessContactsComponent,
    ButtonPrimaryComponent
  },
  data: () => ({
    activeTabName: "business"
  }),
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    }
  }
};
</script>

<style></style>
