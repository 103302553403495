<template>
  <div class="">
    <!-- <TopBarTitleComponent title="" :showBackIcon="false">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent
            title="Nueva Empresa"
            @onSaveClick="goRouter('business.add')"
          />
        </div>
      </template>
    </TopBarTitleComponent> -->

    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="items.length">
          <section class="input-filters">
            <InputSearchComponent @onChangeSearch="onChangeSearch" />
            <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
          </section>

          <paginate
            class="wrapper-pagination"
            name="resultQueryClients"
            :list="resultQueryClients"
            :per="perPaginate"
            :key="searchQuery"
          >
            <div class="card-content-items">
              <b-row>
                <b-col
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="3"
                  v-for="(item, index) in paginated('resultQueryClients')"
                  :key="index"
                >
                  <div
                    class="card card-shadow mb-4"
                    @click="goRouter('business.edit', { id: item.id })"
                  >
                    <div class="card-body card-shadow-body">
                      <div class="business-content-item p-2">
                        <div class="business-image-item">
                          <ImagePreviewCircleComponent :urlImage="item.image" />
                        </div>
                        <div class="business-description-item">
                          <h3 class="h3 font-bold mb-0 str-limit-space">
                            {{ item.name }}
                          </h3>
                          <small class="small"
                            >Creado: {{ item.created_at }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </paginate>
          <paginate-links
            v-show="resultQueryClients.length > perPaginate"
            for="resultQueryClients"
            :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
            :simple="{
              next: 'Siguiente »',
              prev: '« Regresar'
            }"
          ></paginate-links>
        </template>
        <EmptyPage
          :media="emptyMedia1"
          text="Aún no has registrado empresas, empieza a crear tu lista de contactos para ofrecer tus productos o servicios."
          v-else
        />
      </template>
      <SkeletonLoadingCard v-else />
    </b-container>
  </div>
</template>

<script>
//import TopBarTitleComponent from "../../components/TopBarTitle";
import InputSearchComponent from "../../components/InputSearch";
import ImagePreviewCircleComponent from "../../components/ImagePreviewCircle";
import SelectOrderByComponent from "../../components/SelectOrderBy";
import SkeletonLoadingCard from "@/components/Animations/Loading/SkeletonCard";
//import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import EmptyPage from "../../components/EmptyPage";

import businessService from "../services/businessService";
import { emptyMedia1 } from "@/services/resources";

export default {
  name: "BusinessListComponent",
  data: () => ({
    emptyMedia1,
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    paginate: ["resultQueryClients"],
    perPaginate: 12,
    items: []
  }),
  computed: {
    resultQueryClients() {
      let clients = this.items;
      return clients
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            case "z-a":
              return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    async handleGetBusiness() {
      try {
        let response = await businessService.getBusiness();
        if (response.data.clients) {
          this.items = response.data.clients;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    ImagePreviewCircleComponent,
    SelectOrderByComponent,
    SkeletonLoadingCard,
    EmptyPage
  },
  mounted() {
    this.handleGetBusiness();
  }
};
</script>

<style lang="scss" scoped>
.card-content-items {
  .business-content-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 120px;

    .business-image-item {
      margin-right: 1rem;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
}
</style>
