<template>
  <div
    class="image-preview-circle d-flex justify-content-center align-items-center mx-auto"
  >
    <img :src="urlImage" alt="" v-if="urlImage" />
    <i class="el-icon-picture-outline-round" v-else></i>
  </div>
</template>

<script>
export default {
  name: "ImagePreviewCircleComponent",
  props: {
    urlImage: {
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
.image-preview-circle {
  width: 40px;
  height: 40px;
  background-color: #8494a5;
  border-radius: 50%;
  i {
    font-size: 1.5rem;
    color: #fff;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
