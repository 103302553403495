<template>
  <div>
    <div :class="['image-selected', className]" v-if="image">
      <i
        class="el-icon-circle-close"
        @click="handleRemoveImage"
        v-show="!disabled"
      ></i>
      <img :src="image" class="image" />
    </div>
    <el-upload
      :disabled="disabled"
      action="#"
      :auto-upload="false"
      class="avatar-uploader"
      :show-file-list="showFileList"
      ref="upload"
      accept=".png, .jpg, .jpeg"
      :limit="1"
      :on-change="handleChangeFile"
      v-else
    >
      <div>
        <i :class="icon" class="avatar-uploader-icon"></i>
        <div class="el-upload__text">
          <a href="#" class="a-link"
            ><strong class="small font-semi-bold">{{ title }}</strong></a
          >
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { fileBase64 } from "@/assets/utils/files";

export default {
  name: "FileUploadComponent",
  data() {
    return {
      image: this.preview
    };
  },
  watch: {
    preview(v) {
      this.image = v;
    }
  },
  methods: {
    async handleChangeFile(file) {
      let base64 = await fileBase64(file.raw);
      this.image = base64;
      this.emitFileData();
    },
    handleRemoveImage() {
      this.image = null;
      this.emitFileData();
    },

    emitFileData() {
      this.$emit("fileBase64", this.image);
    }
  },
  props: {
    icon: {
      default: "el-icon-picture-outline-round"
    },
    title: {
      default: ""
    },
    preview: {
      default: null
    },
    className: {
      default: null
    },
    showFileList: {
      default: true
    },
    disabled: {
      default: false
    }
  },
  mounted() {
    this.$root.$on("cleanFileUpload", () => {
      this.image = null;
    });
  }
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  border-radius: 6px;
  max-width: 150px;
  padding: 2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: dashed 1px #e3e3e3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 55px;
  color: #e3e3e3;
  text-align: center;
  margin-bottom: 1rem;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.image-selected {
  i {
    font-size: 1.5rem;
    float: right;
    cursor: pointer;
  }

  img {
    display: block;
    margin: auto;
    max-width: 350px;
    width: 100%;
  }

  &.image-profile {
    img {
      height: 140px !important;
      width: 140px !important;
      border-radius: 50% !important;
      background: #0faea8;
      padding: 0.2rem;
    }
  }
}
</style>
